import axios from 'axios';
import { API_URL } from './api';

export const contactService = {
    list (params = {}) {
        console.log('contactService: list');

        const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

        return axios
            .get(`${API_URL}/contacts?${queryString}`)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },

    counter () {
        console.log('contactService: counter');

        return axios
            .get(`${API_URL}/contacts/counter`)
            .then((response) => response);
    },

    status () {
        console.log('contactService: status');

        return axios
            .get(`${API_URL}/contacts/status`)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },

    updateStatus (id, formData) {
        console.log('contactService: updateStatus');

        return axios
            .put(`${API_URL}/contacts/${id}/status`, formData)
            .then((response) => response);
    },

    delete (id, formData) {
        console.log('contactService: updateStatus');

        return axios
            .delete(`${API_URL}/contacts/${id}`, formData)
            .then((response) => response);
    },

    get (id) {
        console.log('contactService: getById', id);

        return axios
            .get(`${API_URL}/contacts/${id}`)
            .then((response) => {
                if (response.data)
                {
                    return response.data;
                }

                return response;
            });
    },
};

export default contactService;
