import axios from 'axios';
import { API_URL } from './api';

export const configurationService = {
    list (params = {}) {
        console.log('configurationService: list', params);

        const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

        return axios
            .get(`${API_URL}/configurations?${queryString}`)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },

    status () {
        console.log('configurationService: status');

        return axios
            .get(`${API_URL}/configurations/status`)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },

    updateStatus (id, formData) {
        console.log('configurationService: updateStatus');

        return axios
            .put(`${API_URL}/configurations/${id}/status`, formData)
            .then((response) => response);
    },

    get (id) {
        console.log('configurationService: getById', id);

        return axios
            .get(`${API_URL}/configurations/${id}`)
            .then((response) => {
                if (response.data)
                {
                    return response.data;
                }

                return response;
            });
    },
};

export default configurationService;
