import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VuejsDialog from 'vuejs-dialog';

import router from './helpers/router';
import store from './store/index';

import App from './components/App.vue';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import './assets/scss/app.scss';

Vue.use(Vuelidate);
Vue.use(VuejsDialog, {
    cancelText: 'Sluiten',
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
