import Vue from 'vue';
import Vuex from 'vuex';

// modules
import configuration from './modules/configuration.module';
import contact from './modules/contact.module';
import priceRequest from './modules/price-request.module';
import statistics from './modules/statistics.module';

// misc
import alert from './modules/alert.module';
import routes from './modules/routes.module';
import account from './modules/account.module';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        alert,
        statistics,
        configuration,
        contact,
        priceRequest,
        routes,
        account,
    },
});

export default store;
