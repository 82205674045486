import { configurationService } from '../../services';

// initial state
const state = {
    list: [],
    status: [],
};

// getters
const getters = {};

// actions
const actions = {
    list ({ dispatch, commit }, data) {
        return configurationService
            .list(data)
            .then((nodes) => {
                commit('setList', nodes);

                return nodes;
            })
            .catch((error) => {
                commit('setList', []);
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    status ({ dispatch, commit }) {
        return configurationService
            .status()
            .then((nodes) => {
                commit('setStatus', nodes);

                return nodes;
            })
            .catch((error) => {
                commit('setStatus', []);
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },
};

// mutations
const mutations = {
    setList (state, nodes) {
        state.list = nodes;
    },
    setStatus (state, nodes) {
        state.status = nodes;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
