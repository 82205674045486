import axios from 'axios';
import { API_URL } from './api';

export const statisticsService = {
    getAll (params = {}) {
        console.log('statisticsService: getAll', params);

        const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

        return axios
            .get(`${API_URL}/statistics?${queryString}`)
            .then((response) => {
                if (response.data)
                {
                    return response.data;
                }

                return response;
            });
    },
};

export default statisticsService;
