<template>
    <div v-if="alert.message" :class="`c-alert c-alert--${alert.type}`">{{alert.message}}</div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Alert',
        computed: {
            ...mapState({
                alert: (state) => state.alert,
            }),
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/_essentials.scss";

    .c-alert {
        @include fixed(left 0 right 0 bottom 0);
        display: block;
        padding: rem(10) rem(20);
        text-align: center;
        z-index: 100;

        &--warning {
            background-color: $brand-warning;
            color: $white;
        }

        &--success {
            background-color: $brand-success;
            color: $white;
        }

        &--error {
            background-color: $brand-danger;
            color: $white;
        }

        .o-main--no-footer & {
            bottom: 0;
        }
    }
</style>
