import { statisticsService } from '../../services';

// initial state
const state = {
    configurations: 0,
    users: 0,
    price_indications: 0,
};

// getters
const getters = {};

// actions
const actions = {
    getAll ({ dispatch, commit }, data) {
        return statisticsService
            .getAll(data)
            .then((stats) => {
                commit('setStats', stats);

                return stats;
            })
            .catch((error) => {
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },
};

// mutations
const mutations = {
    setStats (state, stats) {
        state.configurations = stats.configurations;
        state.users = stats.users;
        state.price_indications = stats.price_indications;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
