import axios from 'axios';
import * as ls from '../helpers/localStorage';

export const API_VERSION = 'v1';
export const API_HOST = 'https://api.vsc.nl'; // https://api.vsc.nl
export const API_URL = `${API_HOST}/api/${API_VERSION}/admin`;
export const API_URL_PUBLIC = `${API_HOST}/api/${API_VERSION}`;

axios.defaults.headers.common = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

// interceptor here ensures that we check for the token in local storage every time an request is made
axios.interceptors.request.use((config) => {
    const token = ls.get('account:token');

    if (token !== null)
    {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    console.error(error);
    return Promise.reject(error);
});
