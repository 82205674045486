<template>
    <div id="app" :class="{ 'is-logged-in': account.status.loggedIn }">
        <app-header/>
        <app-nav v-if="account.status.loggedIn"/>

        <main class="o-main">
            <transition name="fade" mode="out-in" appear>
                <router-view/>
            </transition>
            <alert/>
        </main>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Alert from './Alert.vue';
    import AppHeader from './app/AppHeader.vue';
    import AppNav from './app/AppNav.vue';

    export default {
        components: { AppNav, Alert, AppHeader },
        computed: {
            ...mapState({
                account: (state) => state.account,
            }),
        },
        watch: {
            $route () {
                // clear alert on location change
                this.$store.commit('alert/clear');
            },
        },
    };
</script>

<style lang="scss">
    @import "../../src/assets/scss/essentials";

    html,
    body {
        overflow: hidden;
    }

    #app {
        display: grid;
        grid-template-areas: "header" "body";
        grid-template-columns: 1fr;

        &.is-logged-in {
            @include media-breakpoint-up(lg) {
                grid-template-areas: "header header" "nav body";
                grid-template-columns: rem(250) 1fr;
            }

            @include media-breakpoint-up(xl) {
                grid-template-columns: rem($sidebar-width) 1fr;
            }
        }
    }

    .o-main {
        background-color: $gray-lightest;
        grid-area: body;
        height: calc(100vh - #{rem(map_get($header-height, xs))});
        overflow-y: auto;
        padding: 5% 10%;
        position: relative;

        @include media-breakpoint-up(sm) {
            height: calc(100vh - #{rem(map_get($header-height, sm))});
        }
    }

    .o-container {
        max-width: 85%;
        margin: 0 auto;

        &--small {
            max-width: 50%;
        }
    }

    .c-grid-list {
        align-self: start;
        display: grid;
        grid-gap: rem(15);
    }

    /**
     * TRANSITION
     */

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .15s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    // btn

    .btn-complete-item {
        transition: all .3s;
    }

    .btn-complete-enter,
    .btn-complete-leave-to {
        opacity: 0;
        transform: scale(0.5);
    }

    .btn-complete-leave-active {
        position: absolute;
    }

    .btn-move {
        transition: transform .3s;
    }

    /**
     * LOADER
     */

    .c-loading {
        align-items: center;
        background-color: $gray-lighter;
        display: flex;
        padding: rem(10) rem(20);
        justify-content: center;

        .spinner {
            display: inline-block;
            margin-right: rem(10);
        }
    }

    /**
     * TABLE
     */

    .table {
        display: grid;
        grid-template-columns: rem(100) 1fr;

        @include media-breakpoint-only(sm) {
            margin: 0 auto;
            max-width: 75%;
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr;
        }

        &__thead {

        }

        &__tbody {
            background: $gray-light;
        }

        &__tr {
            border-top: rem(1) solid $gray-light;
            display: grid;
            grid-template-columns: 1fr;

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(5, 1fr);
            }

            @include media-breakpoint-up(lg) {
                grid-template-columns: 1fr 1.5fr repeat(3, 1fr) rem(225);
            }

            &.is-selected {
                outline: rem(1) solid $brand-success;
            }

            .table__thead & {
                @include media-breakpoint-up(md) {
                    display: none;

                    &:first-child {
                        display: grid;
                    }
                }
            }
        }

        &__td,
        &__th {
            background-color: $white;
            padding: rem(10) rem(20);

            @include media-breakpoint-down(xsl) {
                min-height: rem(50);
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            @include media-breakpoint-only(sm) {
                min-height: rem(55);
            }
        }

        &__th {
            border-left: none;
            border-right: none;
        }

        &__td {

        }

        &__th {
            color: $text-color;
            font-weight: $font-weight-black;

            @include media-breakpoint-up(sm) {
                font-size: rem(20);
                padding-top: rem(10);
                padding-bottom: rem(10);
            }

            @include media-breakpoint-up(xl) {
                padding-top: rem(15);
                padding-bottom: rem(15);
            }

            &--order {
                cursor: pointer;
                transition: $transition-base;

                @include hover-focus {
                    color: $gray-base;
                }
            }
        }

        &--full-width {
            .table__th {
                grid-column: 1 / -1;
            }
        }
    }

    /**
    * TABS
    */

    .vue-tabs {

    }

    .vue-tablist {
        @include zero;
        list-style: none;
        display: flex;
        width: 100%;
    }

    .vue-tab {
        background-color: $white;
        border: rem(1) solid $gray-light;
        cursor: pointer;
        padding: rem(15);
        flex: 1 1 auto;
        transition: $transition-base;
        text-align: center;
        margin-left: rem(-1);

        @include hover-focus {
            background-color: lighten($gray-lighter, 5%);
        }

        &[aria-selected="true"] {
            background-color: $brand-primary;
            color: $white;
        }

        @include media-breakpoint-up(sm) {
            padding: rem(15) rem(30);
        }

        @include media-breakpoint-up(xl) {
            padding: rem(20) rem(30);
            font-size: rem(20);
        }
    }

    .vue-tabpanel {
        padding: rem(30) 0;

        @include media-breakpoint-up(sm) {
            padding: rem(40);
        }
    }

    /**
     * FORMS
     */

    .form {
        display: grid;
        grid-gap: rem(40) 0;
        margin: 0 auto;
        max-width: 100%;

        @include media-breakpoint-up(sm) {
            max-width: 85%;
        }

        @include media-breakpoint-up(xl) {
            grid-gap: rem(75) 0;
            max-width: 50%;
        }

        &-fieldset {
            display: grid;
            grid-gap: rem(20);

            &--horizontal {
                @include media-breakpoint-up(sm) {
                    align-items: center;
                    grid-template-columns: 1fr auto;
                }
            }
        }
    }

    .form-submit-btn {
        justify-self: center;
    }

    .custom-control-name {
        align-self: flex-end;
        color: $text-color;
        display: block;
        font-size: rem(24);
        font-weight: $font-weight-black;
        // filter: drop-shadow(0 0 rem(3) rgba(black, .15));
        padding-left: rem(40);
        position: relative;

        @include media-breakpoint-up(xl) {
            font-size: rem(30);
        }

        // bg
        &::before {
            @include square(rem(24));
            @include absolute(top 50% left 0);
            display: block;
            pointer-events: none;
            content: '';
            user-select: none;
            background-color: transparent;
            border: rem(2) solid $gray-light;
            border-radius: 50%;
            transition: all .3s $transition-timing-base;
            transform: translateY(-50%);
        }

        // icon
        &::after {
            @include square(rem(16));
            @include absolute(top 50% left rem(4));
            background: transparent url('/assets/images/check.svg') center center no-repeat;
            display: block;
            content: '';
            transition: all .3s $transition-timing-base;
            transform: scale(0) translateY(-50%);
        }
    }

    .custom-control-label {
        cursor: pointer;
        display: flex;
        line-height: 1.2;
        margin-bottom: 0;
        text-align: left;

        .is-disabled & {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }

    .custom-control-input {
        position: absolute;
        z-index: -1; // Put the input behind the label so it doesn't overlay text
        opacity: 0;

        &:checked ~ .custom-control-name:before {
            border-color: $brand-success;
            background-color: $brand-success !important;
        }

        &:checked ~ .custom-control-name:after {
            transform: scale(1) translateY(-50%);
        }

        &:active ~ .custom-control-name:before {
            color: $brand-success;
            background-color: $brand-success;
        }

        &:disabled {
            ~ .custom-control-name {
                color: $gray-light;

                &::before {
                    background-color: $gray-light;
                    border-color: $gray-light;
                }
            }
        }
    }

    /**
     * MODAL
     */
    .sweet-modal {
        background-color: $gray-lighter;
        box-shadow: none;
        border: rem(1) solid $gray-light;
        border-radius: 0;

        @include media-breakpoint-up(xl) {
            max-width: none;
            width: 50%;
        }

        .form {
            @include media-breakpoint-up(xl) {
                max-width: 75%;
            }
        }

        .sweet-content {
            padding: rem(30);
        }

        .sweet-box-actions {
            top: 0;
            right: 0;

            .sweet-action-close {
                @include flex-center;
                background-color: $brand-primary;
                border-radius: 0;
                transition: $transition-base;

                @include media-breakpoint-up(sm) {
                    @include square(rem(50));
                }

                svg {
                    path {
                        transition: $transition-base;
                        fill: $white;
                    }
                }

                @include hover {
                    background-color: darken($brand-primary, 5%);

                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }

    /**
     * DIALOG
     */

    .dg-main-content {
        padding: rem(30) !important;
        max-width: rem(500) !important;
    }

    .dg-content {
        font-size: rem(20) !important;
    }

    .dg-content-body {
        padding-bottom: rem(30) !important;
        border-bottom: rem(1) solid $gray-light !important;
    }

    .dg-content-footer {
        padding-top: rem(30) !important;
    }

    .dg-btn {
        border-radius: 0 !important;
        border-width: rem(1) !important;

        &--cancel {
            background-color: $brand-primary !important;
        }

        &--ok {
            border-color: $brand-primary !important;
            color: $brand-primary !important;
        }
    }
</style>
