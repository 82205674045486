<template>
    <nav class="c-nav">
        <ul class="c-nav__list">
            <li class="c-nav__item">
                <router-link class="c-nav__link" active-class="c-nav__link--current" exact-active-class="c-nav__link--current" to="/admin" exact>Dashboard</router-link>
            </li>
            <li class="c-nav__item">
                <router-link class="c-nav__link" active-class="c-nav__link--current" exact-active-class="c-nav__link--current" to="/admin/accounts" exact>Gebruikers</router-link>
            </li>
            <li class="c-nav__item">
                <router-link class="c-nav__link" active-class="c-nav__link--current" exact-active-class="c-nav__link--current" to="/admin/configurations" exact>Configuraties</router-link>
            </li>
            <li class="c-nav__item">
                <router-link class="c-nav__link" active-class="c-nav__link--current" exact-active-class="c-nav__link--current" to="/admin/emails" exact>Prijs aanvragen</router-link>
            </li>
            <li class="c-nav__item">
                <router-link class="c-nav__link" active-class="c-nav__link--current" exact-active-class="c-nav__link--current" to="/admin/contacts" exact>Contact aanvragen ({{ counter.new }})</router-link>
            </li>
        </ul>

        <a href="#0" class="c-nav__link c-nav__link--logout" v-on:click.prevent="logoutUser">
            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path>
            </svg>
            Uitloggen
        </a>
    </nav>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        computed: {
            ...mapState({
                counter: (state) => state.contact.counter,
            }),
        },
        created () {
            this.$store.dispatch('contact/counter');
        },
        methods: {
            ...mapActions('account', ['logout']),
            logoutUser () {
                this.logout();
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .c-nav {
        @include fixed(left 0 bottom 0);
        @include set-properties;
        background-color: $gray-dark;
        width: rem($sidebar-width);

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-only(lg) {
            width: rem(250);
        }

        &__list {
            @include zero;
            list-style: none;
        }

        &__item {
            & + & {

            }
        }

        &__link {
            border-bottom: rem(1) solid $gray-darker;
            border-left: rem(3) solid transparent;
            color: $white;
            display: block;
            font-size: rem(18);
            padding: rem(10) rem(20);
            transition: $transition-base;

            @include hover-focus {
                border-left-color: $brand-primary;
                border-bottom-color: darken($gray-darker, 5%);
                background-color: $gray-darker;
            }

            &--current {
                border-left-color: $brand-primary;
            }

            &--logout {
                @include absolute(left 0 right 0 bottom 0);
                border-top: rem(1) solid $gray-darker;
            }
        }
    }
</style>
