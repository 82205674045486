import axios from 'axios';
import { API_URL } from './api';

export const priceRequestService = {
    getAll () {
        console.log('priceRequestService: getAll');

        return axios
            .get(`${API_URL}/price-requests`)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },
};

export default priceRequestService;
