// initial state
import Login from '../../views/account/Login.vue';

const siteTitle = 'Voortman Configurator Portaal';
const delimiter = '-';
const state = {
    routes: [
        {
            path: '/',
            name: 'Inloggen',
            component: Login,
            meta: {
                title: `Inloggen ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // dashboard
        {
            path: '/admin',
            name: 'Dashboard',
            index: 'dashboard',
            component: () => import(/* webpackChunkName: "dashboard" */ '../../views/Dashboard.vue'),
            meta: {
                title: `Dashboard ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // accounts
        {
            path: '/admin/accounts',
            name: 'Accounts',
            component: () => import(/* webpackChunkName: "account" */ '../../views/Accounts.vue'),
            meta: {
                title: `Accounts ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // contacts
        {
            path: '/admin/contacts',
            name: 'Accounts',
            component: () => import(/* webpackChunkName: "contacts" */ '../../views/Contacts.vue'),
            meta: {
                title: `Contact aanvragen ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/admin/contact/:user_id/:id',
            name: 'Contact',
            component: () => import(/* webpackChunkName: "contact" */ '../../views/Contact.vue'),
            meta: {
                title: `Contact ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // configurations
        {
            path: '/admin/configurations',
            name: 'Configuraties',
            component: () => import(/* webpackChunkName: "configurations" */ '../../views/Configurations.vue'),
            meta: {
                title: `Configuraties ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/admin/configuration/:id',
            name: 'Configuratie',
            component: () => import(/* webpackChunkName: "configuration" */ '../../views/Configuration.vue'),
            meta: {
                title: `Configuratie ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // emails
        {
            path: '/admin/emails',
            name: 'Prijs aanvragen',
            component: () => import(/* webpackChunkName: "emails" */ '../../views/PriceRequests.vue'),
            meta: {
                title: `Prijs aanvragen ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // profile
        {
            path: '/admin/account',
            name: 'Account',
            component: () => import(/* webpackChunkName: "account" */ '../../views/account/Account.vue'),
            meta: {
                title: `Account ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/forgot-password',
            name: 'Wachtwoord vergeten',
            component: () => import(/* webpackChunkName: "login" */ '../../views/account/ForgotPasswordEmail.vue'),
            meta: {
                title: `Wachtwoord vergeten ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/reset-password',
            name: 'Wachtwoord herstellen',
            component: () => import(/* webpackChunkName: "login" */ '../../views/account/ForgotPasswordReset.vue'),
            meta: {
                title: `Wachtwoord herstellen ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // otherwise redirect to home
        {
            path: '*',
            name: '404 not found',
            component: () => import(/* webpackChunkName: "404-not-found" */ '../../views/404.vue'),
            meta: {
                title: `404 ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
    ],
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
