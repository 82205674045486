import axios from 'axios';
import * as ls from '../helpers/localStorage';
import { API_URL, API_URL_PUBLIC } from './api';

export const accountService = {
    getAll () {
        console.log('accountService: getAll');

        return axios
            .get(`${API_URL}/accounts`)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },

    get (id) {
        console.log('accountService: get', id);

        return axios
            .get(`${API_URL}/accounts/${id}`)
            .then((response) => {
                // check if we get an account
                if (response.data)
                {
                    return response.data;
                }

                return response;
            });
    },

    user () {
        console.log('accountService: user');

        return axios
            .get(`${API_URL}/account`)
            .then((response) => {
                // check if we get an account
                if (response.data.account)
                {
                    // store the firstname
                    ls.set('account:firstname', response.data.account.firstname);

                    return response.data.account;
                }

                return response;
            });
    },

    login (formData) {
        console.log('accountService: login');

        return axios
            .post(`${API_URL}/login`, formData)
            .then((response) => {
                // check if we get a token
                if (response.data.token)
                {
                    // store the token
                    ls.set('account:token', response.data.token);

                    return response.data.token;
                }

                return response;
            });
    },

    logout () {
        console.log('accountService: logout');

        return axios
            .get(`${API_URL}/logout`)
            .then((response) => {
                // remove the token
                ls.remove('account:token');
                ls.remove('account:firstname');

                return response;
            });
    },

    passwordEmail (formData) {
        console.log('accountService: passwordEmail');

        return axios
            .post(`${API_URL_PUBLIC}/password/email`, formData)
            .then((response) => {
                console.log(response);

                return response;
            });
    },

    passwordReset (formData) {
        console.log('accountService: passwordReset');

        return axios
            .post(`${API_URL_PUBLIC}/password/reset`, formData)
            .then((response) => {
                console.log(response);

                return response;
            });
    },

    delete () {
        console.log('accountService: delete');

        return axios
            .delete(`${API_URL}/account/delete`)
            .then((response) => {
                // remove the token
                ls.remove('account:token');
                ls.remove('account:firstname');
                ls.remove('request:email');
                ls.remove('price:requested');

                return response;
            });
    },

    update (formData) {
        console.log('accountService: update');

        return axios
            .put(`${API_URL}/account`, formData)
            .then((response) => response);
    },
};

export default accountService;
