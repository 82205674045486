<template>
    <header class="c-header">
        <app-logo></app-logo>

        <div class="c-btn-group c-btn-group--stacked">
            <div class="dropdown">
                <div class="[ c-btn c-btn--square ] c-header__btn c-header__btn--steps u-hidden-lg-up dropdown-toggle" v-on:click.prevent="toggleTabDropdown">
                    <svg width="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                    </svg>
                </div>

                <ul class="dropdown-menu" v-show="menuDropdownIsOpen">
                    <router-link class="dropdown__link" to="/admin" exact>Dashboard</router-link>
                    <router-link class="dropdown__link" to="/admin/accounts" exact>Accounts</router-link>
                    <router-link class="dropdown__link" to="/admin/configurations" exact>Configuraties</router-link>
                    <router-link class="dropdown__link" to="/admin/emails" exact>Prijs aanvragen</router-link>
                </ul>
            </div>

            <div class="dropdown" v-if="account.status.loggedIn">
                <a href="#0" class="[ c-btn c-btn--square c-btn--tint ] c-header__btn c-header__btn--account dropdown-toggle" v-on:click.prevent="toggleDropdown">
                    <svg class="u-hidden-md-up" xmlns="http://www.w3.org/2000/svg" width="17.31" height="16.357" viewBox="0 0 17.31 16.357">
                        <circle fill="#707070" cx="4.328" cy="4.328" r="4.328" transform="translate(4.328 0)"></circle>
                        <path fill="#707070" d="M9.655,15C6.766,15,1,16.45,1,19.327v2.164H18.31V19.327C18.31,16.45,12.544,15,9.655,15Z" transform="translate(-1 -5.134)"></path>
                    </svg>

                    <div class="c-header__btn-text u-hidden-sm-down" v-if="account.user">Welkom, {{ account.user.firstname }}</div>
                    <div class="c-header__btn-text u-hidden-sm-down" v-else>Welkom</div>
                </a>

                <ul class="dropdown-menu" v-show="dropdownIsOpen">
                    <router-link class="dropdown__link" to="/admin/account">Account</router-link>
                    <li class="dropdown__divider"></li>
                    <a href="#0" class="dropdown__link" v-on:click.prevent="logoutUser">
                        <svg width="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path>
                        </svg>
                        Uitloggen
                    </a>
                </ul>
            </div>

            <app-button btn-class="[ c-btn c-btn--square c-btn--primary ] c-header__btn" to="#0">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <path fill="#fff" d="M20.01,15.38a11.443,11.443,0,0,1-3.53-.56.977.977,0,0,0-1.01.24L13.9,17.03A15.183,15.183,0,0,1,7.01,10.2L8.96,8.54A1.021,1.021,0,0,0,9.2,7.52a11.153,11.153,0,0,1-.56-3.53A1,1,0,0,0,7.65,3H4.19C3.65,3,3,3.24,3,3.99A17.152,17.152,0,0,0,20.01,21,1.049,1.049,0,0,0,21,19.82V16.37A1,1,0,0,0,20.01,15.38Z" transform="translate(-3 -3)"></path>
                </svg>
            </app-button>
        </div>
    </header>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import AppLogo from './AppLogo.vue';
    import AppButton from './AppButton.vue';
    import * as ls from '../../helpers/localStorage';

    export default {
        components: { AppButton, AppLogo },
        data () {
            return {
                dropdownIsOpen: false,
                menuDropdownIsOpen: false,
            };
        },
        computed: {
            ...mapState({
                account: (state) => state.account,
            }),
        },
        watch: {
            $route () {
                this.dropdownIsOpen = false;
                this.menuDropdownIsOpen = false;
            },
        },
        created () {
            // only get the account when there is a token
            if (ls.get('account:token'))
            {
                this.$store.dispatch('account/user');
            }
        },
        methods: {
            ...mapActions('account', ['logout']),
            toggleDropdown () {
                this.dropdownIsOpen = !this.dropdownIsOpen;
            },
            toggleTabDropdown () {
                this.menuDropdownIsOpen = !this.menuDropdownIsOpen;
            },
            logoutUser () {
                this.logout();
            },
        },
    };
</script>


<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .c-header {
        @include set-properties(height, $header-height);
        align-items: center;
        background-color: $white;
        border-bottom: rem(1) solid $gray-light;
        display: flex;
        grid-area: header;
        justify-content: space-between;
        padding-left: rem(15);

        @include media-breakpoint-up(sm) {
            padding-left: rem(30);
        }

        &__btn {
            & + &,
            &:first-child {
                border-left: rem(1) solid $gray-light;
            }

            &-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &--steps,
            &--account,
            &--config {
                border-bottom: rem(1) solid $gray-light;
            }

            &--account {
                @include media-breakpoint-up(sm) {
                    background-color: $white;
                    color: $text-color;
                    padding: rem(15) rem(30);
                    text-transform: none;
                    width: auto;
                    max-width: rem(200);

                    @include hover {
                        background-color: lighten($gray-lightest, 2%);
                    }
                }
            }

            &--config {
                @include flex-center;
                border-left: rem(1) solid $gray-light;
                background-color: $brand-success;
                color: $white;
                padding: rem(15) rem(30);
                width: auto;
                max-width: rem(175);
            }
        }
    }

    $caret-width: .3em;

    .dropdown {
        position: relative;

        &-toggle {
            &::after {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: $caret-width * .85;
                vertical-align: $caret-width * .85;
                content: "";
                border-top: $caret-width solid;
                border-right: $caret-width solid transparent;
                border-left: $caret-width solid transparent;
            }

            &:empty::after {
                margin-left: 0;
            }
        }

        &-menu {
            @include absolute(top 100% right 0);
            @include zero;
            background-color: $white;
            border: rem(1) solid $gray-light;
            list-style: none;
            margin-top: rem(20);
            min-width: rem(200);
            z-index: 100;

            &:before,
            &:after {
                @include square(0);
                border-style: solid;
                content: "";
            }

            &:before {
                @include absolute(top rem(-10) right rem(32));
                border-width: 0 rem(8) rem(10) rem(8);
                border-color: transparent transparent $white transparent;
                z-index: 1;
            }

            &:after {
                @include absolute(top rem(-12) right rem(30));
                border-width: 0 rem(10) rem(12) rem(10);
                border-color: transparent transparent $gray-light transparent;
            }
        }

        &__divider {
            height: rem(1);
            margin: ($spacer / 2) 0;
            overflow: hidden;
            background-color: $gray-light;
        }

        &__link {
            background-color: $white;
            color: $text-color;
            display: block;
            padding: rem(5) rem(20);
            transition: $transition-base;

            @include hover {
                background-color: lighten($gray-lightest, 2%);
            }

            &.router-link-exact-active {
                background-color: $brand-primary;
                color: $white;
            }

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
</style>
