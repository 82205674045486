import { contactService } from '../../services';

// initial state
const state = {
    list: [],
    status: [],
    counter: {
        new: 0,
        assigned: 0,
        completed: 0,
        total: 0,
    },
};

// getters
const getters = {};

// actions
const actions = {
    list ({ dispatch, commit }, data) {
        return contactService
            .list(data)
            .then((nodes) => {
                commit('setList', nodes);

                return nodes;
            })
            .catch((error) => {
                commit('setList', []);
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    counter ({ dispatch, commit }) {
        return contactService
            .counter()
            .then((counter) => {
                commit('setCounter', counter.data);

                return counter;
            })
            .catch((error) => {
                commit('setCounter', []);
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    delete ({ dispatch }) {
        console.log('contactService: delete');

        return contactService
            .delete()
            .then((result) => {
                console.log(result);
            }).catch((error) => {
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    status ({ dispatch, commit }) {
        return contactService
            .status()
            .then((nodes) => {
                commit('setStatus', nodes);

                return nodes;
            })
            .catch((error) => {
                commit('setStatus', []);
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },
};

// mutations
const mutations = {
    setList (state, nodes) {
        state.list = nodes;
    },
    setStatus (state, status) {
        state.status = status;
    },
    setCounter (state, count) {
        state.counter = count;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
